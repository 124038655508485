// React
import React, { useEffect } from "react";
// Redux
import { Dispatch } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
// Actions
import { getUserSelf, logoutUser } from "./../../actions/user";
// Components

// Style
import { Container, Nav, Navbar /*NavDropdown*/ } from "react-bootstrap";

export const Header = (props: any) => {
	const navigate = useNavigate();
	const location = useLocation();

	const { connected, login } = useSelector((state: any) => state.user);

	const dispatch: Dispatch<any> = useDispatch();

	useEffect(() => {
		dispatch(getUserSelf());
	}, [dispatch]);

	useEffect(() => {
		if (connected && location.pathname === "/login") {
			navigate("/");
		}
	}, [connected, location.pathname, navigate]);

	return (
		<Navbar collapseOnSelect expand='lg' bg='dark' variant='dark'>
			<Container>
				<Navbar.Brand onClick={() => navigate("/")}>TSAdventure - Administration</Navbar.Brand>
				<Navbar.Toggle aria-controls='responsive-navbar-nav' />
				<Navbar.Collapse id='responsive-navbar-nav'>
					<Nav className='me-auto'>
						{connected ? (
							<React.Fragment>
								<Nav.Link onClick={() => navigate("/live")}>Live</Nav.Link>
								<Nav.Link onClick={() => navigate("/datas")}>Datas</Nav.Link>
								<Nav.Link onClick={() => navigate("/locations")}>Locations</Nav.Link>
							</React.Fragment>
						) : null}
					</Nav>
					<Nav>
						{connected ? (
							<React.Fragment>
								<Nav.Link>Hello {login}</Nav.Link>
								<Nav.Link onClick={() => dispatch(logoutUser())}>Sign Out</Nav.Link>
							</React.Fragment>
						) : (
							<React.Fragment>
								<Nav.Link onClick={() => navigate("/login")}>Login</Nav.Link>
							</React.Fragment>
						)}
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
};
