import React from 'react';
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import store from "./reducers/index";

import { USER_AUTH } from "./actions/types";

if (localStorage["userAdminToken"]) {
	store.dispatch({ type: USER_AUTH });
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<Provider store={store}>
		<App />
	</Provider>
);

reportWebVitals();
