// React
import { configureStore } from '@reduxjs/toolkit';
// App Files
import { reducer as reducer_user, IUserState } from './reducer_user';

export interface IRootState {
	user: IUserState;
}

const store = configureStore({
	reducer: {
		user: reducer_user,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
