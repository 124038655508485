// React
import { useEffect, useState } from "react";
// Redux
/*import { Dispatch } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'*/
import { useNavigate } from "react-router-dom";
// Actions
import { getDataSimple } from "../../actions";
// Style
import { Container, Table } from "react-bootstrap";

export const Locations = () => {
	const navigate = useNavigate();

	const [locations, setLocations] = useState<any>([]);

	useEffect(() => {
		let urlLocations = "/locations/all";

		getDataSimple("getLocationsError", true, urlLocations)
			.then((response) => {
				console.log("Locations retrieved:", response.response.data.locations);
				setLocations(response.response.data.locations);
			})
			.catch((e: any) => {});
	}, []);

	return (
		<Container className='main'>
			<h3>Locations :</h3>

			<Table striped bordered hover>
				<thead>
					<tr>
						<th>Id</th>
					</tr>
				</thead>
				<tbody>
					{locations &&
						locations.map((d: any, index: number) => {
							return (
								<tr key={"location" + d._id}>
									<td>
										<a
											style={{ cursor: "pointer" }}
											onClick={() => navigate("/location/" + d._id)}
										>
											{d.name}
										</a>
									</td>
								</tr>
							);
						})}
				</tbody>
			</Table>
		</Container>
	);
};
