// User Actions
export const USER_CONNECTED = 'user_connected';
export const USER_LOGOUT = 'user_logout';
export const USER_AUTH = 'user_auth';
export const USER_GETSELF = 'user_getself';
export const USER_ERROR = 'user_error';

// scene actions
export const SCENE_ERROR = 'scene_error';
export const SCENE_RESET = 'scene_reset';
export const SCENE_ALL = 'scene_all';

// DispatchType
export type DispatchType = (args: any) => any;
// Iaction
export interface IAction {
	type: string;
	payload?: any;
}
