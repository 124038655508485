// React
import React, { useEffect, useState } from "react";
// Imports
import axios from "axios";
// Components
import { Col, Container, Row, Form, Button } from "react-bootstrap";
// Style
import "./datas.css";
import loaderIcon from "../../style/assets/loaderIcon.gif";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const Project = (props: any) => {
	let group = props.group;

	const [sessionsData, setSessionsData] = useState<any>();
	const [loaderImg, setLoaderImg] = useState(false);

	let idExperience = (document.getElementById("idExperience") as any)?.value;

	useEffect(() => {
		console.log("Detect change ?");
		if (props.loadDatas) {
			setLoaderImg(true);
			(async () => {
				let url = REACT_APP_API_URL + "/tsstation/sessions";

				try {
					let res = await axios({
						url: url,
						method: "POST",
						data: {
							groups: [group[0]],
							before: props.dates.before,
							after: props.dates.after,
							idExperience: idExperience,
						},
					});

					if (res) {
						console.log("stats=", res.data);

						setSessionsData(res.data.stats);
						setLoaderImg(false);
					}
				} catch (e: any) {
					console.log("E:", e);
				}

				/*try {
					let res = await axios.get(url);

					let obj = JSON.parse(res.data.sessions);

					setSessionsData(Object.keys(obj).map((key) => [key, obj[key]]));
					setLoaderImg(false);
				} catch (e: any) {
					console.log("E:", e);
				}*/
			})();
		}
	}, [props.loadDatas]);

	const addMarks = (array: any) => {
		let total = 0;
		for (let entry of array) {
			total += entry.amount;
		}

		return total;
	};

	return (
		<React.Fragment>
			<tr className='table-dark' style={{ width: "100% !important" }}>
				<td>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<div>{group[0]}</div>
						{loaderImg && (
							<div className='loaderIcon' style={{ backgroundImage: `url(${loaderIcon})` }}></div>
						)}
					</div>
				</td>
			</tr>
			<tr>
				<td>
					{sessionsData?.kiosks &&
						sessionsData.kiosks.map((kiosk: any, indexKiosk: number) => {
							console.log("kiosk", kiosk);
							return (
								<Row
									key={"kiosk" + indexKiosk}
									style={{ border: "1px solid black", marginBottom: "2px" }}
								>
									<Col xs={6} style={{ wordWrap: "break-word" }}>
										{kiosk[0]}
									</Col>
									{/* <Col xs={2}>{sd[1].usersTotal}</Col> */}
									<Col xs={6} style={{ display: "flex", flexDirection: "column" }}>
										{kiosk[1].experiences &&
											kiosk[1].experiences.length > 0 &&
											kiosk[1].experiences.map((exp: any, indexM: number) => {
												console.log("passage");

												return (
													<React.Fragment key={"m" + indexM}>
														<Row>
															<Col xs={4} style={{ backgroundColor: "lightblue" }}>
																{exp.name}
															</Col>
															<Col xs={4} style={{ backgroundColor: "lightgreen" }}>
																{exp.marks
																	.filter((m: any) => m.amount > 0)
																	.sort((m1: any, m2: any) => {
																		return m1.value - m2.value;
																	})
																	.map((m: any, indexMk: number) => {
																		return (
																			<Row key={"m" + indexM + "mk" + indexMk}>
																				<Col xs={6}>
																					{m.value >= 0 ? m.value : "N/A"}
																				</Col>
																				<Col xs={6}>{m.amount}</Col>
																			</Row>
																		);
																	})}
															</Col>
															<Col
																style={{
																	display: "flex",
																	flexDirection: "column",
																	backgroundColor: "yellow",
																}}
															>
																{exp.langs.map((l: any, indexL: number) => {
																	return (
																		<Row key={"m" + indexM + "l" + indexL}>
																			<Col xs={6}>{l.value}</Col>
																			<Col xs={6}>{l.amount}</Col>
																		</Row>
																	);
																})}
															</Col>
														</Row>
														<Row
															style={{
																marginBottom: "4px",
																borderBottom:
																	indexM < kiosk[1].experiences.length - 1
																		? "1px solid grey"
																		: "",
															}}
														>
															<Col xs={4} style={{ wordWrap: "break-word" }}>
																{"Totaux : " + addMarks(exp.marks) + " sessions"}
															</Col>
															<Col xs={4}>
																<Row>
																	<Col xs={6}></Col>
																	<Col xs={6}>
																		{addMarks(
																			exp.marks.filter((m: any) => m.value >= 0)
																		) + " notes"}
																	</Col>
																</Row>
															</Col>
															<Col xs={4}>
																<Row xs={12}>
																	<Col xs={6}></Col>
																	<Col xs={6}>{addMarks(exp.langs)}</Col>
																</Row>
															</Col>
														</Row>
													</React.Fragment>
												);
											})}
									</Col>
								</Row>
							);
						})}
				</td>
			</tr>
		</React.Fragment>
	);
};

export const Datas = () => {
	const [tsStations, setTsStations] = useState<Map<string, any>>();
	useEffect(() => {
		(async () => {
			let url = REACT_APP_API_URL + "/tsstation/all";

			try {
				let res = await axios.get(url);

				let groups = new Map();
				for (let tsStation of res.data.tsStations) {
					if (groups.has(tsStation.group)) {
						groups.get(tsStation.group).push(tsStation);
					} else {
						groups.set(tsStation.group, []);
						groups.get(tsStation.group).push(tsStation);
					}
				}

				console.log("groups =", groups);

				setTsStations(groups);
			} catch (e: any) {
				console.log("E:", e);
			}
		})();
	}, []);

	useEffect(() => {}, [tsStations]);

	/* Manage Dates */
	const [beforeDate, setBeforeDate] = useState("");
	const [afterDate, setAfterDate] = useState("2018-01-01T00:00");

	useEffect(() => {
		let beforeInput = document.getElementById("before-time");

		if (beforeInput) {
			console.log("found");
			const tmp = new Date();
			const tmpyear = tmp.getFullYear();
			const tmpmonth = String(tmp.getMonth() + 1).padStart(2, "0");
			const tmpday = String(tmp.getDate() + 1).padStart(2, "0");
			const formattedDate = `${tmpyear}-${tmpmonth}-${tmpday}T00:00`;

			setBeforeDate(formattedDate);
		}

		let afterInput = document.getElementById("after-time");

		if (afterInput) {
			console.log("found");
			const tmp = new Date();
			tmp.setDate(tmp.getDate() - 30);
			const tmpyear = tmp.getFullYear();
			const tmpmonth = String(tmp.getMonth() + 1).padStart(2, "0");
			const tmpday = String(tmp.getDate() + 1).padStart(2, "0");
			const formattedDate = `${tmpyear}-${tmpmonth}-${tmpday}T00:00`;

			setAfterDate(formattedDate);
		}
	}, []);

	const [valueSelected, setValueSelected] = useState<string | null>("");
	const [loadDatas, setLoadDatas] = useState(false);

	useEffect(() => {
		(async () => {
			let url = REACT_APP_API_URL + "/tsstation/sessions";

			try {
				let res = await axios({
					url: url,
					method: "POST",
					data: {},
				});
			} catch (e: any) {
				console.log("E:", e);
			}
		})();
	}, []);

	return (
		<React.Fragment>
			<Container className={"datas"} data-select2-id={4}>
				{/* <Row>
					<Col xs={12}>
						<Form>
							<Row>
								<Col xs={12} md={9}>
									<Form.Group>
										<Form.Label>Borne(s)</Form.Label>
									</Form.Group>
								</Col>
								<Col xs={12} md={3}>
									<Button variant='secondary'>Filtrer</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row> */}

				<div className='main-firstline'>
					<div className='main-firstline-title'>
						<h3>{"Projets :"}</h3>
					</div>
					<div className='main-firstline-sessionchoice'>
						<input
							onChange={(e) => {
								setAfterDate(e.target.value);
							}}
							type='datetime-local'
							id='after-time'
							name='after-time'
							value={afterDate}
						/>
						<input
							onChange={(e) => {
								setBeforeDate(e.target.value);
							}}
							type='datetime-local'
							id='before-time'
							name='before-time'
							value={beforeDate}
						/>
						<select onChange={() => {}} name='idExperience' id='idExperience'>
							<option value=''>--Toutes les stations--</option>
							{tsStations &&
								Array.from(tsStations.entries()).map((exp: any) => {
									return (
										<option key={exp.name} value={exp.name}>
											{exp.name}
										</option>
									);
								})}
						</select>
						<button
							className='main-firstline-sessionchoice-button'
							onClick={async () => {
								// setPage(-1);
								console.log("click");
								setValueSelected(null);

								setTimeout(() => {
									let valueSelectedTmp = document.getElementById("idExperience") as HTMLSelectElement;

									if (valueSelectedTmp) {
										setValueSelected(valueSelectedTmp.value);
									}

									setLoadDatas(true);
								}, 100);
							}}
						>
							Get Datas!
						</button>
					</div>
				</div>

				<Row style={{ paddingInline: "18px" }}>
					<Col xs={6}>{"Borne:"}</Col>
					{/* <Col xs={2}>{"Utilisateurs:"}</Col> */}
					<Col xs={2} style={{ backgroundColor: "lightblue" }}>
						{"Film:"}
					</Col>
					<Col xs={2} style={{ backgroundColor: "lightgreen" }}>
						{"Notes:"}
					</Col>
					<Col xs={2} style={{ backgroundColor: "yellow" }}>
						{"Langues:"}
					</Col>
				</Row>
				<table className='table' style={{ width: "100%" }}>
					<tbody style={{ width: "100%" }}>
						{tsStations &&
							Array.from(tsStations.entries()).map((tsStation: any) => {
								if (
									(valueSelected !== null && valueSelected === "") ||
									valueSelected === tsStation[0]
								) {
									return (
										<Project
											key={tsStation[0]}
											group={tsStation}
											loadDatas={loadDatas}
											dates={{
												after: afterDate,
												before: beforeDate,
											}}
										/>
									);
								}
							})}
					</tbody>
				</table>
			</Container>
		</React.Fragment>
	);
};
