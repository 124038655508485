export const parseRotation = (value: string) => {
	let rotations = value.split("t");
	let rotationsNew: String[] = [];

	rotations.forEach((v: any, i: number) => {
		rotationsNew.push(String(Number(v) / 1000));
	});

	return rotationsNew;
};
