// React
import React, { useEffect, useState } from "react";
// Components
import { Button, Container, Form } from "react-bootstrap";
// Redux
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { loginUser } from "./../../actions/user";
import useFetch from "./../../hooks/useFetch";
// CSS
import "./login.css";

declare global {
	interface Window {
		google: any;
	}
}

// URL list
export const API_URL = process.env.REACT_APP_API_URL;

export const Login = () => {
	const [data, setData] = useState<any>({});
	const [appleAuth, setAppleAuth] = useState<any>({});

	const dispatch: Dispatch<any> = useDispatch();

	const { handleGoogle, loading, error } = useFetch(API_URL + "/auth/googlelogin");

	useEffect(() => {
		if (window.google) {
			window.google.accounts.id.initialize({
				client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
				callback: handleGoogle,
			});

			window.google.accounts.id.renderButton(document.getElementById("loginDiv"), {
				// type: "standard",
				theme: "filled_black",
				// size: "small",
				text: "signin_with",
				shape: "pill",
			});

			// google.accounts.id.prompt()
		}
	}, [handleGoogle]);

	const updateData = (e: any) => {
		setData({
			...data,
			[e.target.name]: e.target.value,
		});
	};

	const onFormSubmit = (e: any) => {
		e.preventDefault();

		if (data.hasOwnProperty("email") && data.hasOwnProperty("password")) {
			//console.log(data)
			dispatch(loginUser(data.email as string, data.password as string));
		}
	};

	return (
		<Container className={"main"}>
			<div className='login'>
				<Form onSubmit={onFormSubmit}>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Email address</Form.Label>
						<Form.Control name='email' type='email' placeholder='Enter email' onChange={updateData} />
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicPassword'>
						<Form.Label>Password</Form.Label>
						<Form.Control name='password' type='password' placeholder='Password' onChange={updateData} />
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicCheckbox'>
						<Form.Check type='checkbox' label='Check me out' />
					</Form.Group>
					<div className='d-grid gap-2'>
						<Button variant='primary' type='submit' size='lg'>
							Submit
						</Button>
					</div>
				</Form>
				<div className='separator'>OR</div>
				<div>
					{error && <p style={{ color: "red" }}>{error}</p>}
					{loading ? <div>Loading....</div> : <div id='loginDiv'></div>}
				</div>
			</div>
		</Container>
	);
};
