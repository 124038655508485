const moment = require("moment");

export const onLiveCheck = (datas: any, setUsers: React.Dispatch<any>) => {
	let ud: Map<string, any> = new Map();

	for (let d of datas) {
		// Update lastUpdate
		let lastModElem = document.getElementById(d.nick + "_lastMod");
		if (lastModElem) {
			lastModElem.innerHTML = d.lastConnection ? moment(new Date(d.lastConnection)).fromNow() : "N/A";
		}
		for (let headset of d.data) {
			let sn = headset.sn.includes("pico_") ? headset.sn.substr(5) : headset.sn;

			let elemLiveLast = document.getElementById(sn + "_liveLast");
			if (elemLiveLast) {
				if (headset.headsetDatas) {
					elemLiveLast.innerHTML = headset.headsetDatas.liveLast
						? moment(new Date(headset.headsetDatas.liveLast)).fromNow() : "/";
				} else {
					elemLiveLast.innerHTML = headset.liveLast
						? moment(new Date(headset.liveLast)).fromNow()
						: "/";
				}
			}
		}

		if (d.group) {
			if (!ud.has(d.group)) {
				ud.set(d.group, []);
			}

			ud.get(d.group)?.push({
				...d,
				headsets: d.data,
			});
		} else {
			if (!ud.has("others")) {
				ud.set("others", []);
			}

			ud.get("others")?.push({
				...d,
				headsets: d.data,
			});
		}
	}

	setUsers(ud);
};
