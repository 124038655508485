// React
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import "./live.css";
import moment from "moment";

const clientState = {
	OFFLINE: 0,
	ONLINE: 1,
};

export const Devices = (device: any) => {
	device = device.device;

	const [deployed, setDeployed] = useState(false);

	return (
		<tr className='table-secondary' style={{ width: "100%" }}>
			<td
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					width: "100%",
					height: "100%",
					padding: "0",
				}}
			>
				<Row
					style={{ width: "100%", display: !deployed ? "none" : "flex", cursor: "pointer" }}
					onClick={() => setDeployed(!deployed)}
				>
					<Col
						id={device.sn + "_rotation"}
						xs={2}
						md={1}
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{/*device.type === "TSmini" && (*/}
							<div className='scene sceneTSmini'>
								<div className='cube'>
									<div className='cube__face cube__face--front'>
										<div className='lens lens-left'>
											<div className='pupil'></div>
										</div>
										<div className='lens lens-right'>
											<div className='pupil'></div>
										</div>
									</div>
									<div className='cube__face cube__face--back'></div>
									<div className='cube__face cube__face--right'>👂</div>
									<div className='cube__face cube__face--left'>👂</div>
									<div className='cube__face cube__face--top'></div>
									<div className='cube__face cube__face--bottom'></div>
								</div>
							</div>
						{/*})*/}
					</Col>
					<Col
						xs={10}
						md={11}
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							padding: "0",
						}}
					>
						<Row style={{ width: "100%" }}>
							<Col xs={10} md={4}>
								{device.name || device.sn}
							</Col>
							<Col
								className={device.sn + "_battery"}
								xs={1}
								md={1}
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "flex-end",
									alignItems: "center",
									textAlign: "right",
								}}
							>
								{device.headsetDatas?.battery}
							</Col>
							<Col
								xs={1}
								className='d-flex d-md-none'
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "flex-end",
									alignItems: "center",
									textAlign: "right",
								}}
							>
								<div
									style={{
										paddingBottom: "4px",
										backgroundColor: "lightgrey",
										aspectRatio: "1/1",
										height: "1.2em",
										transformOrigin: "center center",
										transform: "rotateZ(90deg)",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									{">"}
								</div>
							</Col>
							<Col id={device.sn + "_title"} xs={8} md={4}>
								{device.movie?.title}
							</Col>
							<Col className={device.sn + "_player"} xs={4} md={2} style={{ textAlign: "right" }}>
								{device.playerDatas?.player}
							</Col>
							<Col
								md={1}
								className='d-none d-md-flex'
								style={{
									flexDirection: "row",
									justifyContent: "flex-end",
									alignItems: "center",
									textAlign: "right",
								}}
							>
								<div
									style={{
										paddingBottom: "4px",
										backgroundColor: "lightgrey",
										aspectRatio: "1/1",
										height: "1.2em",
										transformOrigin: "center center",
										transform: "rotateZ(90deg)",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									{">"}
								</div>
							</Col>
							<Col
								xs={12}
								md={12}
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "flex-end",
									alignItems: "center",
								}}
							>
								<div
									id={device.sn + "_liveLast"}
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "flex-end",
										alignItems: "center",
										textAlign: "right",
										marginRight: "12px",
										color: "grey",
										fontSize: "12px",
									}}
								>
									/
								</div>
								<div
									style={{
										aspectRatio: "1/1",
										backgroundColor:
											/*product.state === clientState.ONLINE ? */ "green" /* : "red"*/,
										height: "16px",
										borderRadius: "50%",
									}}
								></div>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row
					style={{ width: "100%", display: deployed ? "none" : "flex", cursor: "pointer" }}
					onClick={() => setDeployed(!deployed)}
				>
					<Col xs={6} md={6}>
						{device.name || device.sn}
					</Col>
					<Col
						xs={3}
						md={3}
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
						}}
						className={device.sn + "_player"}
					>
						{device.playerDatas?.player}
					</Col>
					<Col
						className={device.sn + "_battery"}
						xs={1}
						md={1}
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
							alignItems: "center",
							textAlign: "right",
						}}
					>
						{device.headsetDatas?.battery}
					</Col>
					<Col
						xs={1}
						md={1}
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
							alignItems: "center",
						}}
					>
						<div
							style={{
								aspectRatio: "1/1",
								backgroundColor: /*product.state === clientState.ONLINE ? */ "green" /* : "red"*/,
								height: "16px",
								borderRadius: "50%",
							}}
						></div>
					</Col>
					<Col
						xs={1}
						md={1}
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
							alignItems: "center",
							textAlign: "right",
						}}
					>
						<div
							style={{
								paddingBottom: "4px",
								paddingRight: "4px",
								backgroundColor: "lightgrey",
								aspectRatio: "1/1",
								height: "1.2em",
								transformOrigin: "center center",
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
								alignItems: "center",
							}}
						>
							{"<"}
						</div>
					</Col>
				</Row>
			</td>
		</tr>
	);
};

export const Products = (product: any) => {
	product = product.product;

	return (
		<React.Fragment>
			<tr className='table-default' style={{ width: "100%" }}>
				<td
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						width: "100%",
						height: "100%",
						padding: "0",
					}}
				>
					<Row style={{ width: "100%" }}>
						<Col
							xs={12}
							md={6}
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center",
							}}
						>
							{product.nick}
							<div className='version-block'>{product.version}</div>
						</Col>
						<Col
							xs={12}
							md={6}
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
								alignItems: "center",
							}}
						>
							<div
								id={product.nick + "_lastMod"}
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "flex-end",
									alignItems: "center",
									textAlign: "right",
									marginRight: "12px",
									color: "grey",
									fontSize: "12px",
								}}
							>
								Never
							</div>
							<div
								style={{
									aspectRatio: "1/1",
									backgroundColor:
										product.state === clientState.ONLINE
											? moment(new Date()).diff(moment(new Date(product.lastConnection))) <=
											  1000 * 60 * 5
												? "green"
												: "orange"
											: "red",
									height: "16px",
									borderRadius: "50%",
								}}
							></div>
						</Col>
					</Row>
				</td>
			</tr>
			{product.data && product.data.length > 0 && (
				<tr>
					<td style={{ width: "100%", padding: "0" }}>
						<table className='table' style={{ width: "calc(100% - 12px)", margin: "0 0 0 12px" }}>
							<tbody>
								{product.data &&
									product.data.map((d: any) => {
										return <Devices key={d.sn} device={d} />;
									})}
							</tbody>
						</table>
					</td>
				</tr>
			)}
		</React.Fragment>
	);
};

export const Project = (group: any) => {
	group = group.group;
	return (
		<React.Fragment>
			<tr className='table-dark' style={{ width: "100% !important" }}>
				<td>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "left",
							alignItems: "center",
						}}
					>
						<div>{group[0]}</div>
					</div>
				</td>
			</tr>
			{group[1] &&
				group[1]
					.filter((u: any) => u.groups == "stamstation")
					.map((u: any) => {
						return <Products key={u.nick} product={u} />;
					})}
		</React.Fragment>
	);
};
