// React
import React, { } from 'react';
// Components
import { Container } from 'react-bootstrap';
// Css
import "./footer.css"

export const Footer = () => {
    return (
        <div className='footer'>
            <Container>
                <p>Contact Us - TSAdventure</p>
                <p className='copyright'>©2023 Timescope. All rights reserved.</p>
            </Container>
        </div>
    );
}