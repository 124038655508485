import { parseRotation } from "../../utils/rotation";

export const onLiveDataStation = (
	datas: any,
	userDatasRef: React.MutableRefObject<any>,
	setUsers: React.Dispatch<any>
) => {
	if (datas.id) {
		let index = -1;
		let group: any;
		for (let groupTmp of userDatasRef.current) {
			let indexTmp = groupTmp[1].findIndex((ud: any) => {
				return ud.nick === datas.id;
			});

			if (indexTmp > -1) {
				group = groupTmp[1];
				index = indexTmp;
			}
		}

		if (index >= 0 && group) {
			let headsetIndex = group[index].headsets?.findIndex((h: any) => h.sn === datas.sn);

			let sn = datas.sn.includes("pico_") ? datas.sn.substr(5) : datas.sn;

			if (headsetIndex >= 0) {
				let headset = {
					...datas.data,
					sn: sn,
				};

				group[index].headsets[headsetIndex] = headset;
			} else {
				if (!group[index].headsets) {
					group[index].headsets = [];
				}

				let headset = {
					...datas.data,
					sn: sn,
				};

				group[index].headsets.push(headset);

				if (userDatasRef.current.find((g: any) => g[1].id === group.id)) {
					userDatasRef.current.find((g: any) => g[1].id === group.id)[1] = group;
				}
			}

			let elemMovie = document.getElementById(sn + "_title");
			let elemsPlayer = document.getElementsByClassName(sn + "_player");
			let elemRotation = document.getElementById(sn + "_rotation");
			let elemLiveLast = document.getElementById(sn + "_liveLast");
			let elemsBattery = document.getElementsByClassName(sn + "_battery");
			if (elemMovie && elemsPlayer && elemRotation && elemsBattery) {
				if (datas.data?.movie?.title !== undefined) {
					elemMovie.innerHTML = datas.data.movie.title;
				}
				if (datas.data?.playerDatas?.player !== undefined) {
					for (let elemPlayer of elemsPlayer) {
						let timeFormatted =
							datas.data.playerDatas.player / 60 > 0
								? "" + Math.floor(datas.data.playerDatas.player / 60)
								: "0";
						timeFormatted += ":";
						if (datas.data.playerDatas.player % 60 < 10) {
							timeFormatted += "0";
						}
						timeFormatted += "" + Math.floor(datas.data.playerDatas.player % 60);
						(elemPlayer as HTMLElement).innerHTML = timeFormatted;
					}
				}

				for (const childElemRotation of elemRotation.children) {
					// if (childElemRotation.classList.contains("sceneTSmini")) {
						for (const childScene of childElemRotation.children) {
							let rotations = parseRotation(datas.data?.headsetDatas?.rotation);

							let rotationActual = (childScene as HTMLElement).style.transform;
							let rotX = rotationActual.indexOf("rotateX");
							let rotY = rotationActual.indexOf("rotateY");
							let rotZ = rotationActual.indexOf("rotateZ");
							let rotXfinal;
							let rotYfinal;
							let rotZfinal;

							if (rotX > -1) {
								rotX += 8;
								rotXfinal = rotationActual.slice(
									rotX,
									rotX + rotationActual.slice(rotX).indexOf("deg")
								);
							}
							if (rotY > -1) {
								rotY += 8;
								rotYfinal = rotationActual.slice(
									rotY,
									rotY + rotationActual.slice(rotY).indexOf("deg")
								);
							}
							if (rotZ > -1) {
								rotZ += 8;
								rotZfinal = rotationActual.slice(
									rotZ,
									rotZ + rotationActual.slice(rotZ).indexOf("deg")
								);
							}

							if (
								Math.abs(Number(rotXfinal) - Number(rotations[0])) < 20 &&
								Math.abs(Number(rotYfinal) - Number(rotations[2])) < 20 &&
								Math.abs(Number(rotZfinal) - Number(rotations[1])) < 20
							) {
								(childScene as HTMLElement).style.transition = "transform 0.2s";
							} else {
								(childScene as HTMLElement).style.transition = "";
							}

							(childScene as HTMLElement).style.transform =
								"translateZ(-100px) rotateX(" +
								Number(-rotations[0]) +
								"deg) rotateY(" +
								Number(-rotations[2]) +
								"deg) rotateZ(" +
								Number(rotations[1]) +
								"deg)";
						}
					// }
					/* if (childElemRotation.classList.contains("sceneTSmini")) {
						for (const childScene of childElemRotation.children) {
							let rotations = parseRotation(datas.data?.headsetDatas?.rotation);

							let rotationActual = (childScene as HTMLElement).style.transform;
							let rotX = rotationActual.indexOf("rotateX");
							let rotY = rotationActual.indexOf("rotateY");
							let rotZ = rotationActual.indexOf("rotateZ");
							let rotXfinal;
							let rotYfinal;
							let rotZfinal;

							if (rotX > -1) {
								rotX += 8;
								rotXfinal = rotationActual.slice(
									rotX,
									rotX + rotationActual.slice(rotX).indexOf("deg")
								);
							}
							if (rotY > -1) {
								rotY += 8;
								rotYfinal = rotationActual.slice(
									rotY,
									rotY + rotationActual.slice(rotY).indexOf("deg")
								);
							}
							if (rotZ > -1) {
								rotZ += 8;
								rotZfinal = rotationActual.slice(
									rotZ,
									rotZ + rotationActual.slice(rotZ).indexOf("deg")
								);
							}

							if (
								Math.abs(Number(rotXfinal) - Number(rotations[0])) < 20 &&
								Math.abs(Number(rotYfinal) - Number(rotations[2])) < 20 &&
								Math.abs(Number(rotZfinal) - Number(rotations[1])) < 20
							) {
								(childScene as HTMLElement).style.transition = "transform 0.2s";
							} else {
								(childScene as HTMLElement).style.transition = "";
							}

							(childScene as HTMLElement).style.transform =
								"translateZ(-100px) rotateX(" +
								Number(-rotations[0]) +
								"deg) rotateY(" +
								Number(-rotations[2]) +
								"deg) rotateZ(" +
								Number(rotations[1]) +
								"deg)";
						}
					} */
				}

				// let elemsCube = document.getElementsByClassName("cube");

				// for (let elemCube of elemsCube) {
				// 	let rotations = parseRotation(datas.data?.headsetDatas?.rotation);

				// 	(elemCube as HTMLElement).style.transform = "translateZ(-100px) rotateY(" + rotations[0] + "deg)";
				// }

				// elemRotation.innerHTML = datas.data?.headsetDatas?.rotation || 'N/A';
				for (let elemBattery of elemsBattery) {
					(elemBattery as HTMLElement).innerHTML = datas.data?.headsetDatas?.battery;
				}
			}
		} else {
			// On ajoute le membre ?
			// console.log("Add user ?");
		}
	}
};
