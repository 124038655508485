// React
import React from "react";
import {
	unstable_HistoryRouter as HistoryRouter,
	Routes,
	Route,
} from "react-router-dom";
import { history } from "./history";
// React Redux
import { useSelector } from "react-redux";
import { IRootState } from "./reducers";
// Style
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
// Imports
import { Main } from "./components/Main/Main";
import { Login } from "./components/Login/Login";
import { StamLive } from "./components/live/StamLive";
import { Datas } from "./components/Datas/Datas";
import { Locations } from "./components/Locations/Locations";
import { Location } from "./components/Locations/Location";
import { Footer } from "./components/Partials/Footer";
import { Header } from "./components/Partials/Header";

function App() {
	let user = useSelector((state: IRootState) => state.user);
	return (
		<HistoryRouter history={history}>
			<Header />
			<Routes>
				{user.connected && <Route path='/' element={<Main />} />}
				<Route path='/login' element={<Login />} />

				{/* All App's routes */}
				{user.connected && <Route path='/live' element={<StamLive />} />}
				{user.connected && <Route path='/datas' element={<Datas />} />}
				{user.connected && (
					<Route path='/locations' element={<Locations />} />
				)}
				{user.connected && <Route path='/location/:id' element={<Location />} />}

				{/* Default */}
				<Route path='*' element={<Login />} />
			</Routes>
			<Footer />
		</HistoryRouter>
	);
}

export default App;
