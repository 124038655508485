// React
import React, { useEffect, useState, useRef } from "react";
// Natscope
import { NatscopeClient } from "../../natscope_modules/natscope-core";
// Style
import { Container } from "react-bootstrap";
import { onLiveDataStation } from "./onLiveDataStation";
import { onLiveCheck } from "./onLiveCheck";
import { Project } from "./Projects";

const REACT_APP_STAMSTRAP_LIVE_URL_STATION = process.env.REACT_APP_STAMSTRAP_LIVE_URL_STATION;
const REACT_APP_STAMSTRAP_LIVE_PORT_STATION = process.env.REACT_APP_STAMSTRAP_LIVE_PORT_STATION;

export const StamLive = (props: any) => {
	const [natscopeClient, setNatscopeClient] = useState<NatscopeClient>();
	const [natscopeClientId, setNatscopeClientId] = useState<string>("");

	const [users, setUsers] = useState<any>([]);
	const userDatasRef = useRef(users);

	useEffect(() => {
		userDatasRef.current = [...users];
	}, [users]);

	useEffect(() => {
		console.log("start live");

		let nat = new NatscopeClient({
			port: Number(REACT_APP_STAMSTRAP_LIVE_PORT_STATION),
			url: String(REACT_APP_STAMSTRAP_LIVE_URL_STATION),
		});

		nat.join_room("live", false);
		setNatscopeClient(nat);
	}, []);

	useEffect(() => {
		if (natscopeClient) {
			natscopeClient.onMyRoomReady.on(function () {
				roomReady();
			});
		}
	}, [natscopeClient]);

	let roomReady = () => {
		if (natscopeClient && natscopeClient.myroom) {
			natscopeClient.myroom.addEvent({
				eventName: "onLiveDataStation",
				type: "multicast",
			});

			natscopeClient.myroom.addEvent({
				eventName: "onLiveCheck",
				type: "multicast",
			});

			natscopeClient.myroom.events.get("onLiveDataStation")?.on((data) => {
				onLiveDataStation(data[0][0], userDatasRef, setUsers);
			});

			natscopeClient.myroom.events.get("onLiveCheck")?.on((data) => {
				onLiveCheck(data[0][0], setUsers);
			});

			const user = {
				id: natscopeClient.me.id,
				nick: natscopeClient.me.nick,
				groups: "stadmin",
			};
			Object.assign(natscopeClient.me, user);

			setNatscopeClientId(natscopeClient.me.id);

			natscopeClient.me_infos(natscopeClient.me);

			console.log("myroom on");
		}
	};

	return (
		<React.Fragment>
			<Container className={"main"}>
				<div>Users :</div>
				<div>
					{Array.from(users.entries()).map((u: any) => {
						if (u[0] === "others") {
							return (
								<React.Fragment key={"others" + u.id}>
									{u[1]
										.filter((u: any) => u.groups == "stadmin")
										.map((u: any) => {
											return <div key={u.id}>{u.nick}</div>;
										})}
								</React.Fragment>
							);
						}
					})}
				</div>

				{/* {Array.from(users.entries()).length > 1 ? (
					<React.Fragment> */}

				<div>Projets :</div>
				<table className='table' style={{ width: "100%" }}>
					<tbody style={{ width: "100%" }}>
						{Array.from(users.entries()).map((g: any) => {
							return <Project key={g[0]} group={g} />;
						})}
					</tbody>
				</table>

				{/* </React.Fragment>
				) : (
					<React.Fragment>{"Only one project"}</React.Fragment>
				)} */}
			</Container>
		</React.Fragment>
	);
};
