// React
import React, { useEffect, useState, useRef } from "react";
// Redux
/*import { Dispatch } from 'redux';
import { useSelector, useDispatch } from 'react-redux';*/
import { useParams } from "react-router-dom";
import { io, Socket } from "socket.io-client";
import axios from "axios";
// Components
import { Header } from "./../Partials/Header";
import { Footer } from "./../Partials/Footer";
// Style
import {
	Container,
	Button,
	ButtonGroup,
	Card,
	Row,
	Col,
	Form,
} from "react-bootstrap";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	ArcElement,
	LineElement,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Line, Bar, Pie } from "react-chartjs-2";
import { postDataSimple } from "../../actions";
// Css
//import "./manager.css";

export const SERVER_URL = process.env.REACT_APP_SERVER_URL;

ChartJS.register(
	CategoryScale,
	LinearScale,
	ArcElement,
	BarElement,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

export const Location = () => {
	let { id } = useParams();

	const [stations, setStations] = useState<Array<any>>([]);
	const [stationsSelected, setStationsSelected] = useState<Array<any>>([]);

	let scales = [
		{ id: "day", name: "Today" },
		{ id: "week", name: "Week" },
		{ id: "month", name: "Month" },
		{ id: "year", name: "Year" },
	];

	const [totalSessions, setTotalSessions] = useState<number>(0);
	const [totalSessionsFree, setTotalSessionsFree] = useState<number>(0);
	const [totalSessionsSales, setTotalSessionsSales] = useState<number>(0);
	const [totalTakesSales, setTotalTakesSales] = useState<number>(0);
	const [totalSales, setTotalSales] = useState<number>(0);
	const [worldsPercent, setWorldsPercent] = useState<any>({});

	let tempDate = new Date();
	tempDate = new Date();
	tempDate.setDate(1);

	const [dateFrom, setDateFrom] = useState<Date>(new Date(tempDate));

	tempDate = new Date();
	tempDate.setDate(1);
	tempDate.setMonth(tempDate.getMonth() + 1);
	tempDate.setDate(tempDate.getDate() - 1);

	const [dateTo, setDateTo] = useState<Date>(new Date(tempDate));

	const [scale, setScale] = useState<String>("month");

	const [data1, setData1] = useState<any>({
		labels: [],
		datasets: [],
	});

	const [data2, setData2] = useState<any>({
		labels: [],
		datasets: [],
	});

	const [data3, setData3] = useState<any>({
		labels: [],
		datasets: [],
	});

	useEffect(() => {
		let urlStations = "/tsstation/all";

		postDataSimple("getStationsError", false, urlStations, {
			locations: [id],
		}).then((response) => {
			setStations(response.response.data.tsStations);
		});

		//let url = process.env.REACT_APP_API_URL + "/tscapture/stats?scale="+scale+"&from=" + finalDateFrom + "&to=" + finalDateTo + "&device=" + id + "";
		//let urlPrint = process.env.REACT_APP_API_URL + "/tscapture/statsprint?scale="+scale+"&from=" + finalDateFrom + "&to=" + finalDateTo + "&device=" + id + "";

		/*axios({
            url: urlPrint,
            method: "GET",
        }).then((response) => {
            //console.log(response.data);

			let labels = []
            let data3sets = []
            let dataPrinters = {}

			for (let p of response.data.data.printers) {
                (dataPrinters as any)[p] = [];
            }

			for (let d of response.data.data.details) {
                labels.push(d.date)

				for (let p of response.data.data.printers) {
                    if (d.printers[p]) {
                        (dataPrinters as any)[p].push(d.printers[p])
                    } else {
                        (dataPrinters as any)[p].push(0)
                    }
                }
			}

			for (let dp in dataPrinters) {
                data3sets.push({
                    label: '' + dp + '',
                    data: (dataPrinters as any)[dp],
                    borderColor: 'rgb(' + (Math.floor(Math.random() * (250 - 20 + 1)) + 20) + ', ' + (Math.floor(Math.random() * (250 - 20 + 1)) + 20) + ', ' + (Math.floor(Math.random() * (250 - 20 + 1)) + 20) + ')'
                    //backgroundColor: 'rgba(40, 200, 40, 0.5)',
                })
            }

			setData3({
                labels: labels,
                datasets: data3sets
            })

		});
*/

		/* axios({
            url: url,
            method: "GET",
        }).then((response) => {
            //console.log(response.data);
            //setDevices(response.data.tsCaptures);

            let labels = []
            let data = []
            let data2sets = []

            let dataWorlds = {}

            for (let w of response.data.data.worlds) {
                (dataWorlds as any)[w] = [];
            }

            for (let d of response.data.data.details) {
                labels.push(d.date)
                data.push(d.sales)

                for (let w of response.data.data.worlds) {
                    if (d.worlds[w]) {
                        (dataWorlds as any)[w].push(d.worlds[w])
                    } else {
                        (dataWorlds as any)[w].push(0)
                    }
                }
            }

            for (let dw in dataWorlds) {
                data2sets.push({
                    label: '' + dw + '',
                    data: (dataWorlds as any)[dw],
                    borderColor: 'rgb(' + (Math.floor(Math.random() * (250 - 20 + 1)) + 20) + ', ' + (Math.floor(Math.random() * (250 - 20 + 1)) + 20) + ', ' + (Math.floor(Math.random() * (250 - 20 + 1)) + 20) + ')'
                    //backgroundColor: 'rgba(40, 200, 40, 0.5)',
                })
            }

            //console.log(dataWorlds)

            setData1({
                labels: labels,
                datasets: [
                    {
                        label: 'Chiffre d\'affaire',
                        data: data,
                        borderColor: 'rgb(20, 200, 20)',
                        backgroundColor: 'rgba(40, 200, 40, 0.5)',
                    }
                ],
            })

            setData2({
                labels: labels,
                datasets: data2sets
            })

            setTotalSessions(response.data.data.totalSessions)
            setTotalSessionsFree(response.data.data.totalSessionsFree)
            setTotalSessionsSales(response.data.data.totalSessionsSales)
            setTotalTakesSales(response.data.data.totalCount)
            setTotalSales(response.data.data.totalSales)

            setWorldsPercent(response.data.data.worldsDetailsPercent)

        }).catch((e: any) => { });

		*/
	}, []);

	useEffect(() => {
		console.log("Stations selected: ", stationsSelected);

		let finalDateFrom =
			"" +
			dateFrom.getFullYear() +
			"-" +
			(dateFrom.getMonth() + 1) +
			"-" +
			dateFrom.getDate() +
			"";
		let finalDateTo =
			"" +
			dateTo.getFullYear() +
			"-" +
			(dateTo.getMonth() + 1) +
			"-" +
			dateTo.getDate() +
			"";

		let urlDatas = "/tsstation/datas";

		postDataSimple("getStationsDatasError", false, urlDatas, {
			scale: scale,
			stations: stationsSelected,
			after: finalDateFrom,
			before: finalDateTo,
		}).then((response) => {
			let labels3 = [];
			let data3sets = [];
			let dataExperiences = {};

			for (let e of response.response.data.data.experiences) {
				(dataExperiences as any)[e] = [];
			}

			for (let d of response.response.data.data.details) {
				labels3.push(d.date);

				for (let e of response.response.data.data.experiences) {
					if (d.experiences[e]) {
						(dataExperiences as any)[e].push(d.experiences[e]);
					} else {
						(dataExperiences as any)[e].push(0);
					}
				}
			}

			for (let de in dataExperiences) {
				data3sets.push({
					label: "" + de + "",
					data: (dataExperiences as any)[de],
					borderColor:
						"rgb(" +
						(Math.floor(Math.random() * (250 - 20 + 1)) + 20) +
						", " +
						(Math.floor(Math.random() * (250 - 20 + 1)) + 20) +
						", " +
						(Math.floor(Math.random() * (250 - 20 + 1)) + 20) +
						")",
					//backgroundColor: 'rgba(40, 200, 40, 0.5)',
				});
			}

			setData3({
				labels: labels3,
				datasets: data3sets,
			});

			// *******
			let labels = [];
			let data1sets = [];
			let data2sets = [];

			let dataMarks = {};
			let dataLangs = [];

			for (let m of response.response.data.data.marks) {
				(dataMarks as any)[m] = [];
			}

			for (let d of response.response.data.data.details) {
				labels.push(d.date);

				for (let m of response.response.data.data.marks) {
					if (d.marks[m]) {
						(dataMarks as any)[m].push(d.marks[m]);
					} else {
						(dataMarks as any)[m].push(0);
					}
				}
			}

			for (let dm in dataMarks) {
				data1sets.push({
					label: "" + dm + "",
					data: (dataMarks as any)[dm],
					backgroundColor:
						"rgb(" +
						(Math.floor(Math.random() * (250 - 20 + 1)) + 20) +
						", " +
						(Math.floor(Math.random() * (250 - 20 + 1)) + 20) +
						", " +
						(Math.floor(Math.random() * (250 - 20 + 1)) + 20) +
						")",
					//backgroundColor: 'rgba(40, 200, 40, 0.5)',
				});
			}

			setData1({
				labels: labels,
				datasets: data1sets,
			});

			let labels2 = [];
			for (let l of response.response.data.data.langs) {
				labels2.push(l);

				data2sets.push(response.response.data.data.langsDetails[l]);
			}
			/*for (let dl in dataLangs) {
				data2sets.push({
					label: "" + dl + "",
					data: (dataLangs as any)[dl],
					backgroundColor:
						"rgb(" +
						(Math.floor(Math.random() * (250 - 20 + 1)) + 20) +
						", " +
						(Math.floor(Math.random() * (250 - 20 + 1)) + 20) +
						", " +
						(Math.floor(Math.random() * (250 - 20 + 1)) + 20) +
						")",
					//backgroundColor: 'rgba(40, 200, 40, 0.5)',
				});
			}*/

			console.log(data2sets);

			setData2({
				labels: labels2,
				datasets: [
					{
						data: data2sets,
						backgroundColor: data2sets.map(() => {
							return (
								"rgb(" +
								(Math.floor(Math.random() * (250 - 20 + 1)) + 20) +
								", " +
								(Math.floor(Math.random() * (250 - 20 + 1)) + 20) +
								", " +
								(Math.floor(Math.random() * (250 - 20 + 1)) + 20) +
								")"
							);
						}),
					},
				],
			});

			/*setTotalSessions(response.data.data.totalSessions)
            setTotalSessionsFree(response.data.data.totalSessionsFree)
            setTotalSessionsSales(response.data.data.totalSessionsSales)
            setTotalTakesSales(response.data.data.totalCount)
            setTotalSales(response.data.data.totalSales)

            setWorldsPercent(response.data.data.worldsDetailsPercent)*/
		});
	}, [stationsSelected, dateFrom, dateTo]);

	let options = {
		responsive: true,
		plugins: {
			legend: {
				position: "top" as const,
			},
			title: {
				display: false,
				text: "Chart.js Line Chart",
			},
		},
	};

	let optionsBar = {
		responsive: true,
		plugins: {
			legend: {
				position: "top" as const,
			},
			title: {
				display: false,
				text: "Chart.js Bar Chart",
			},
		},
		scales: {
			x: {
				stacked: true,
			},
			y: {
				stacked: true,
			},
		},
	};

	const handlePrevious = (event: any) => {
		if (scale === "year") {
			let newDate = new Date(dateFrom);
			newDate.setFullYear(tempDate.getFullYear() - 1);
			newDate.setMonth(0);
			newDate.setDate(1);
			newDate.setHours(2);
			newDate.setMinutes(0);
			newDate.setSeconds(0);

			setDateFrom(newDate);

			newDate = new Date(dateFrom);
			newDate.setFullYear(tempDate.getFullYear() - 1);
			newDate.setMonth(11);
			newDate.setDate(31);
			newDate.setHours(23);
			newDate.setMinutes(59);
			newDate.setSeconds(0);
			setDateTo(newDate);
		} else if (scale === "month") {
			let newDate = new Date(dateFrom);
			newDate.setFullYear(newDate.getFullYear());
			newDate.setMonth(newDate.getMonth() - 1);
			newDate.setDate(1);
			newDate.setHours(2);
			newDate.setMinutes(0);
			newDate.setSeconds(0);
			setDateFrom(newDate);

			newDate = new Date(newDate);
			newDate.setMonth(newDate.getMonth() + 1);
			newDate.setHours(newDate.getHours() - 2);
			setDateTo(newDate);
		} else if (scale === "week") {
			let newDate = new Date(dateFrom);
			//newDate.setDate(7);
			let daysUntilMonday = newDate.getDay() - 1;

			let firstDayOfWeek = new Date(newDate);
			firstDayOfWeek.setDate(newDate.getDate() - daysUntilMonday - 7);

			let lastDayOfWeek = new Date(firstDayOfWeek);
			lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

			setDateFrom(firstDayOfWeek);
			setDateTo(lastDayOfWeek);
		} else if (scale === "day") {
			let newDate = new Date(dateFrom);
			newDate.setDate(newDate.getDate() - 1);
			newDate.setHours(1);
			newDate.setMinutes(0);
			newDate.setSeconds(0);
			setDateFrom(newDate);

			newDate.setHours(23);
			newDate.setMinutes(59);
			newDate.setSeconds(0);
			setDateTo(newDate);
		}
	};

	const handleAfter = (event: any) => {
		if (scale === "year") {
			let newDate = new Date(dateFrom);
			newDate.setFullYear(tempDate.getFullYear() + 1);
			newDate.setMonth(0);
			newDate.setDate(1);
			newDate.setHours(2);
			newDate.setMinutes(0);
			newDate.setSeconds(0);

			setDateFrom(newDate);

			newDate = new Date(dateFrom);
			newDate.setFullYear(tempDate.getFullYear() + 1);
			newDate.setMonth(11);
			newDate.setDate(31);
			newDate.setHours(23);
			newDate.setMinutes(59);
			newDate.setSeconds(0);
			setDateTo(newDate);
		} else if (scale === "month") {
			let newDate = new Date(dateFrom);
			newDate.setFullYear(newDate.getFullYear());
			newDate.setMonth(newDate.getMonth() + 1);
			newDate.setDate(1);
			newDate.setHours(2);
			newDate.setMinutes(0);
			newDate.setSeconds(0);
			setDateFrom(newDate);

			newDate = new Date(newDate);
			newDate.setMonth(newDate.getMonth() + 1);
			newDate.setHours(newDate.getHours() - 2);
			setDateTo(newDate);
		} else if (scale === "week") {
			let newDate = new Date(dateFrom);
			//newDate.setDate(7);
			let daysUntilMonday = newDate.getDay() - 1;

			let firstDayOfWeek = new Date(newDate);
			firstDayOfWeek.setDate(newDate.getDate() - daysUntilMonday + 7);

			let lastDayOfWeek = new Date(firstDayOfWeek);
			lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

			setDateFrom(firstDayOfWeek);
			setDateTo(lastDayOfWeek);
		} else if (scale === "day") {
			let newDate = new Date(dateFrom);
			newDate.setDate(newDate.getDate() + 1);
			newDate.setHours(1);
			newDate.setMinutes(0);
			newDate.setSeconds(0);
			setDateFrom(newDate);

			newDate.setHours(23);
			newDate.setMinutes(59);
			newDate.setSeconds(0);
			setDateTo(newDate);
		}
	};

	const handleScaleChange = (s: string) => {
		//console.log("handle change "+s)
		setScale(s);
		if (s === "day") {
			let newDate = new Date();
			newDate.setHours(1);
			newDate.setMinutes(0);
			newDate.setSeconds(0);
			setDateFrom(newDate);

			newDate = new Date();
			newDate.setHours(23);
			newDate.setMinutes(59);
			newDate.setSeconds(0);
			setDateTo(newDate);
		} else if (s === "week") {
			let newDate = new Date();
			//newDate.setDate(7);
			let daysUntilMonday = newDate.getDay() - 1;

			let firstDayOfWeek = new Date(newDate);
			firstDayOfWeek.setDate(newDate.getDate() - daysUntilMonday);

			let lastDayOfWeek = new Date(firstDayOfWeek);
			lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

			setDateFrom(firstDayOfWeek);
			setDateTo(lastDayOfWeek);
		} else if (s === "month") {
			let tempDate = new Date();
			tempDate.setDate(1);

			setDateFrom(new Date(tempDate));

			tempDate = new Date();
			tempDate.setDate(1);
			tempDate.setMonth(tempDate.getMonth() + 1);
			tempDate.setDate(tempDate.getDate() - 1);

			setDateTo(new Date(tempDate));
		} else if (s === "year") {
			let tempDate = new Date();
			tempDate.setMonth(0);
			tempDate.setDate(1);
			tempDate.setHours(2);
			tempDate.setMinutes(0);
			tempDate.setSeconds(0);

			setDateFrom(new Date(tempDate));

			tempDate = new Date();
			tempDate.setMonth(11);
			tempDate.setDate(31);
			tempDate.setHours(23);
			tempDate.setMinutes(59);
			tempDate.setSeconds(0);

			setDateTo(new Date(tempDate));
		}
	};

	const handleDateChangeFrom = (event: any) => {
		setDateFrom(new Date(event.target.value));
	};
	const handleDateChangeTo = (event: any) => {
		setDateTo(new Date(event.target.value));
	};

	return (
		<Container>
			<h3>Location : {id}</h3>
			<Form.Group as={Col} controlId='my_multiselect_field'>
				<Form.Label>My multiselect</Form.Label>
				<Form.Control
					as='select'
					multiple
					value={stationsSelected}
					onChange={(e) => {
						setStationsSelected(
							[].slice
								.call((e.target as any).selectedOptions)
								.map((item: any) => item.value)
						);
					}}
				>
					{stations.map((station: any) => {
						return (
							<option key={"station-" + station._id} value={station._id}>
								{station.nick}
							</option>
						);
					})}
				</Form.Control>
			</Form.Group>
			<Row>
				<Col md={12} xs={12}>
					<Card>
						<Card.Header as='h5'>Expériences</Card.Header>
						<Card.Body>
							{/*<Card.Title>Special title treatment</Card.Title>*/}
							<Card.Text>
								<Line options={options} data={data3} />
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col md={12} xs={12}>
					<Card>
						<Card.Header as='h5'>Notations</Card.Header>
						<Card.Body>
							{/*<Card.Title>Special title treatment</Card.Title>*/}
							<Card.Text>
								<Bar options={optionsBar} data={data1} />
							</Card.Text>
							<ButtonGroup>
								<Button onClick={handlePrevious}>Previous</Button>
								{scales.map((s: any, i: number) => {
									return (
										<Button
											key={"scale_" + i}
											variant={scale === s.id ? "secondary" : "primary"}
											onClick={() => handleScaleChange(s.id)}
										>
											{s.name}
										</Button>
									);
								})}
								<Button onClick={handleAfter}>After</Button>
							</ButtonGroup>
							<br />
							<input
								type='date'
								id='start'
								name='trip-start'
								value={dateFrom.toISOString().split("T")[0]}
								onChange={handleDateChangeFrom}
							/>{" "}
							:
							<input
								type='date'
								id='start'
								name='trip-start'
								value={dateTo.toISOString().split("T")[0]}
								onChange={handleDateChangeTo}
							/>
							<br />
							<span>
								<b>Nombre d'expériences</b> : {totalSessions}
							</span>
							<br />
							<span>
								<b>Nombre d'expériences gratuite</b> : {totalSessionsFree}
							</span>
							<br />
							<span>
								<b>Nombre d'expériences achetées</b> : {totalSessionsSales}
							</span>
							<br />
							<span>
								<b>Nombre de photos imprimées</b> : {totalTakesSales}
							</span>
							<br />
							<span>
								<b>Chiffre d'affaire total</b> : {totalSales}€
							</span>
						</Card.Body>
					</Card>
				</Col>
				{/*<Col md={6} xs={12}>
					<Card>
						<Card.Header as="h5">Featured</Card.Header>
						<Card.Body>
							<Card.Title>Special title treatment</Card.Title>
							<Card.Text>
								With supporting text below as a natural lead-in to additional content.
							</Card.Text>
							<Button variant="primary">Go somewhere</Button>
						</Card.Body>
					</Card>
				</Col>*/}
			</Row>
			<Row>
				<Col md={12} xs={12}>
					<Card>
						<Card.Header as='h5'>Worlds</Card.Header>
						<Card.Body>
							{/*<Card.Title>Special title treatment</Card.Title>*/}
							<Card.Text>
								<Pie data={data2} />
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<div style={{ height: "200px" }}></div>
		</Container>
	);
};
